@tailwind base;
@tailwind components;
@tailwind utilities;


.tab {
    @apply text-white p-2 rounded-lg transition-colors duration-300;
  }
  
  .tab-active {
    @apply bg-purple-900;
  }
  
  .nav-button {
    @apply text-white flex flex-col items-center justify-center p-2;
  }