body{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: #0f062c;
}

.tab {
  padding: 0.75rem;
  cursor: pointer;
  flex: 1;
  text-align: center;
  border-radius: 0.5rem;
}

.tab-active {
  background-color: #7e57c2;
  border-bottom: 2px solid #ffd700;
}

.task-card {
  background-color: #5e35b1;
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.task-card2 {
  border: 1px solid;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
}

.nav-button {
  color: white;
  background-color: #4a148c;
  padding: 0.75rem;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add this CSS to your styles (e.g., in a CSS file or in a style tag) */
.coin-click-animation {
  animation: coin-bounce 0.2s ease-in-out;
}

@keyframes coin-bounce {
  0% {
    transform: scale(1);
    filter: brightness(100%);
  }
  50% {
    transform: scale(1.1);
    filter: brightness(150%);
  }
  100% {
    transform: scale(1);
    filter: brightness(100%);
  }
}


@keyframes floatUp {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-150px);
    opacity: 0;
  }
}

.coin-increment {
  position: absolute;
  animation: floatUp 1s forwards;
  color: white;
  font-size: 1.5rem;
  font-weight: bolder;
  pointer-events: none;
}
